import httpService from './http.service';
import axios from 'axios';

export const updateChurchSettings = (data) =>
  httpService.patch(`/church/:churchID/`, data);

export const updateOnboardingChurchSettings = (church, data, token) =>
  httpService.patch(
    `/church/${church}/`,
    data,
    token ? { Authorization: `Bearer ${token}` } : null
  );

export const getChurchSettings = () => httpService.get(`/church/:churchID`);

export const getChurchLocationList = async (churchID) => {
  const response = await httpService.get(
    `/church/locations/list/?church_id=${churchID || ':churchID'}`
  );
  return response;
};

export const updateChurchLocation = (data, token) =>
  httpService.patch(
    `/church/locations/${data.id}/`,
    data,
    token ? { Authorization: `Bearer ${token}` } : null
  );

export const createChurchLocation = (data, token) =>
  httpService.post(
    `/church/locations/create/`,
    data,
    token ? { Authorization: `Bearer ${token}` } : null
  );

export const removeChurchLocation = (location_id, token) =>
  httpService.remove(
    `/church/locations/${location_id}/`,
    null,
    token ? { Authorization: `Bearer ${token}` } : null
  );

export const getChurchVideoChoices = (token) =>
  httpService.get(
    `/church/togather-welcome-video-choices/`,
    token ? { Authorization: `Bearer ${token}` } : null
  );

export const createHostedVideo = (video, token) =>
  httpService.post(
    `/resources/hosted-video/upload/`,
    video,
    token ? { Authorization: `Bearer ${token}` } : null
  );

export const uploadHostVideo = async (url, video) => {
  const response = await axios.put(`${url}`, video);
  return response;
};
export const churchSearch = (value) => httpService.get(`church/search/?search=${value}`)

export const churchSearchByDomain = (value) => httpService.get(`church/search/?subdomain=${value}`)

export const churchHelper = (data) =>
  httpService.post(`/church/new-church-request/create/`, data);

export const editGroupTypeVerbose = async (church_id, data, token) => {
  const response = await httpService.patch(
    `/church/group-type-dynamic-names/${church_id}/`,
    data,
    token ? { Authorization: `Bearer ${token}` } : null
  );
  return response;
};

export const getOnboardingData = (session) =>
  httpService.get(
    `/payments/church-subscription/${session}/onboarding-status/`
  );

export const updateOnboardingData = (session, data) =>
  httpService.patch(
    `/payments/church-subscription/${session}/onboarding-status/`,
    data
  );

export const createChurch = (data, token) =>
  httpService.post(
    '/church/create/',
    data,
    token ? { Authorization: `Bearer ${token}` } : null
  );

export const createGroupTypeVerbose = (data, token) =>
  httpService.post(
    '/church/group-type-dynamic-names/create/',
    data,
    token ? { Authorization: `Bearer ${token}` } : null
  );

export const subdomainCheck = (subdomain, token) =>
  httpService.get(
    `/church/subdomain/is-unique/?subdomain=${subdomain}`,
    token ? { Authorization: `Bearer ${token}` } : null
  );

export const getChurchStaffList = (id, token) =>
  httpService.get(
    `/member/church-staff/list/?church_id=${id}`,
    token ? { Authorization: `Bearer ${token}` } : null
  );

export const addChurchStaff = (data, token) =>
  httpService.post(
    `/member/church-staff/create/`,
    data,
    token ? { Authorization: `Bearer ${token}` } : null
  );

export const updateChurchStaffRole = (data) =>
  httpService.patch(`member/church/staff/role-update/`, data);

export const generateClientSecret = (customerId, token) =>
  httpService.get(
    `/payments/payment-method/client-secret/?customer_id=${customerId}`,
    token ? { Authorization: `Bearer ${token}` } : null
  );

export const confirmPaymentMethod = (data, token) =>
  httpService.post(
    `payments/payment-method/addition-confirm/`,
    data,
    token ? { Authorization: `Bearer ${token}` } : null
  );

export const createSubscription = (data, token) =>
  httpService.post(
    `/payments/v1/organization-subscription/create/`, 
    data,
    token ? { Authorization: `Bearer ${token}` } : null
  );

export const getBillingSummary = (data,  token) =>
  httpService.get(
    `/payments/onboarding/billing-summary/`,
    token ? { Authorization: `Bearer ${token}` } : null,
    {
      params: data,
    }
  );

export const getCurrentSubscriptionDetails = (data) =>
  httpService.get(`/payments/organization-subscription/details/`, null, {
    params: data,
  });

export const getBillingHistory = (data) =>
  httpService.get(
    `/payments/organization-subscription/billing-history/`,
    null,
    {
      params: data
    }
  );

export const getCustomerCard = (customer_id, token) =>
  httpService.get(
    `/payments/payment-method/cards/list/`,
    token ? { Authorization: `Bearer ${token}` } : null,
    {
      params: {
        customer_id: customer_id,
      },
    }
  );


export const getGroupTypeVerboseNames = () => httpService.get(`/church/group-type-dynamic-names/:churchID/`)

export const getSubscriptionsList = () =>
  axios.get('/payments/v1/church-subscription-plans/list/', {
    baseURL: `${process.env.NEXT_PUBLIC_STAGING_API}`,
  });

export const postCheckout = (data) =>
  httpService.post('/payments/v1/church-subscription/checkout/', data);

export const updatePaymentStatus = (sessionID) =>
  httpService.get(`/payments/church-subscription/status-update/?status=success&session_id=${sessionID}`)

export const getSupportedCountries = () =>
  httpService.get('/church/supported-countries/');

export const dataExportDownload = (
  platform,
  churchID,
  exportType,
  dateRange = null
) => 
  axios.get(
    `/reports/data-export/${platform}/`,
    {
      baseURL: `${process.env.NEXT_PUBLIC_STAGING_API}`,
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
      params: {
        church_id: churchID,
        export_type: exportType,
        ...(dateRange ? dateRange : {}),
      }
    }
  );

export const postDemo = (data) =>
  httpService.post('/demos/church-demo/create/', data);
