import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { MembersList } from '../../services/members.service';

const initialState = {
  members: [],
};

export const getMembersThunk = createAsyncThunk(
  'members/getMembers',
  async (data) => {
    const response = await MembersList(data);
    return response;
  }
);

const MembersSlice = createSlice({
  name: 'members',
  initialState,
  extraReducers: {
    [getMembersThunk.pending]: (state) => {
      state.isLoading = true;
    },
    [getMembersThunk.fulfilled]: (state, action) => {
      state.members = action.payload;
      state.isLoading = false;
    },
    [getMembersThunk.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export default MembersSlice.reducer;
