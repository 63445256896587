import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { familyMemberStatus } from '../../constans/settings';
import { editGroupTypeVerbose } from '../../services/church.service';
import * as Sentry from "@sentry/nextjs";
import {
  userLogin,
  userRegister,
  getUserByToken,
  updateProfileInfo,
  getUserByGoogle,
  getUserByApple,
  sendLetterByResetPassword,
  resetPassword,
  updatePhoto,
  voteRSVP,
  getUserSetupGuide,
  updateUserSetupGuide,
} from '../../services/user.service';

const initialState = {
  userData: {},
  userChurch: {},
  userDataReg: {},
  userSetup: {},
  isLoading: false,
  error: [],
  errorByResetPassword: [],
  pendingConnection: [],
  removedConnections: [],
  groupNames: {},
  userCredentials: {},
  token: '',
  runTour: false,
};

export const getAccountDataThunk = createAsyncThunk(
  'loginPage/getAccountData',
  async (data, { rejectWithValue }) => {
    try {
      const response = await userLogin(data);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAccountData = createAsyncThunk(
  'account-setup/getAccountData',
  async (data, { rejectWithValue }) => {
    try {
      const response = await userLogin(data);
      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAccountDataByRegister = createAsyncThunk(
  'registerPage/getAccountDataByRegister',
  async (dataReg, { rejectWithValue }) => {
    try {
      const data = await userRegister(dataReg);
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  }
);

export const updateChurchSettings = createAsyncThunk(
  'church/updateChurchSettings',
  async (dataInfo) => {
    const data = await updateChurchSettings(dataInfo);
    return data;
  }
);

export const getUser = createAsyncThunk('user/getUserByToken', async () => {
  const data = await getUserByToken();
  return data;
});

export const updateProfile = createAsyncThunk(
  'user/updateProfile',
  async ({ id, updateData }) => {
    try {
      const data = await updateProfileInfo(updateData);
      const updatedData = await getUserByToken();
      return updatedData;
    } catch (err) {
      console.log(err);
    }
  }
);

export const getUserByGoogleToken = createAsyncThunk(
  'user/getUserByGoogle',
  async (authData, { rejectWithValue }) => {
    try {
      const data = await getUserByGoogle(authData);
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  }
);

export const getUserByAppleToken = createAsyncThunk(
  'user/getUserByApple',
  async (authData, { rejectWithValue }) => {
    try {
      const data = await getUserByApple(authData);
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  }
);

export const sendLetter = createAsyncThunk(
  'user/sendLetter',
  async (email, { rejectWithValue }) => {
    try {
      const data = await sendLetterByResetPassword(email);
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  }
);

export const setNewPassword = createAsyncThunk(
  'user/resetPassword',
  async (newPassword, { rejectWithValue }) => {
    try {
      const data = await resetPassword(newPassword);
      return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response);
    }
  }
);

export const updateAvatar = createAsyncThunk(
  'user/updateAvatar',
  async (img) => {
    try {
      const data = await updatePhoto(img);
      return data;
    } catch (err) {
      console.log(err);
    }
  }
);

export const addRsvpDP = createAsyncThunk('user/addRSVP', async (rsvp) => {
  try {
    const data = await voteRSVP(rsvp);
    return data;
  } catch (err) {
    console.log(err);
  }
});

export const updateGroupNames = createAsyncThunk(
  '/church/group-type-dynamic-names',
  async (church_id, names) => {
    try {
      const data = await editGroupTypeVerbose(church_id, names);
      return data;
    } catch (err) {
      console.log(err);
    }
  }
);

export const getSetup = createAsyncThunk(
  'user/getSetup',
  async (churchMembershipID) => {
    try {
      const res = await getUserSetupGuide(churchMembershipID);
      return res.data.data;
    } catch (err) {
      console.log(err);
      if (!err.response) {
        throw err;
      }
    }
  }
);

export const updateSetup = createAsyncThunk(
  'user/updateSetup',
  async (data) => {
    try {
      const res = await updateUserSetupGuide(data.churchMembershipID, data.data);
      return res.data.data;
    } catch (err) {
      console.log(err);
      if (!err.response) {
        throw err;
      }
    }
  }
);

const AuthSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearToken(state) {
      Sentry.setUser(null);
      state.token = '';
      state.userData = {};
    },
    signOut(state) {
      Sentry.setUser(null);
      state.userData = {};
    },
    clearRegisterData(state) {
      Sentry.setUser(null);
      state.userDataReg = {};
      setUserCredentials;
    },
    clearErrors(state) {
      state.error = [];
    },
    setUserCredentials(state, { payload }) {
      state.userCredentials = payload;
      Sentry.setUser({ id: payload.user_id, email: payload.email });
    },
    switchTheChurch(state, { payload }) {
      localStorage.removeItem('current_church');
      localStorage.removeItem('newChurchId');
      localStorage.removeItem('access_token');
      localStorage.setItem('current_church', JSON.stringify(payload?.church));
      localStorage.setItem('newChurchId', payload?.church.id);
    },
    setRunTourInStore(state, { payload }) {
      state.runTour = payload;
    },
  },
  extraReducers: {
    [getAccountData.pending]: (state) => {
      state.isLoading = true;
    },
    [getAccountData.fulfilled]: (state, action) => {
      const { data } = action.payload.data;
      state.token = data.access_token;
      state.userData = data.user;
      state.isLoading = false;
      Sentry.setUser({ id: data.user.id, email: data.user.email });
    },
    [getAccountData.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload.errors;
      Sentry.setUser(null);
    },
    [getAccountDataThunk.pending]: (state) => {
      state.isLoading = true;
    },
    [getSetup.fulfilled]: (state, action) => {
      state.userSetup = action.payload;
    },
    [getSetup.rejected]: (state, action) => {
      state.error = action.payload.errors;
    },
    [updateSetup.fulfilled]: (state, action) => {
      state.userSetup = action.payload;
    },
    [updateSetup.rejected]: (state, action) => {
      state.error = action.payload.errors;
    },
    [getAccountDataThunk.fulfilled]: (state, action) => {
      const { data } = action.payload.data;
      localStorage.setItem('access_token', data.access_token);
      localStorage.setItem('refresh_token', data.refresh_token);
      const date = new Date();
      date.setSeconds(date.getSeconds() + +data.expires_in);
      localStorage.setItem('expires_in', date);
      state.userData = data.user;
      state.token = data.access_token;
      state.isLoading = false;
      Sentry.setUser({ id: data.user.id, email: data.user.email });
    },
    [getAccountDataThunk.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload.errors;
      Sentry.setUser(null);
    },
    [getAccountDataByRegister.pending]: (state) => {
      state.isLoading = true;
    },
    [getAccountDataByRegister.fulfilled]: (state, action) => {
      const data = action.payload;
      if (action.payload.data.errors.length > 0) {
        state.error = action.payload.data.errors;
        return;
      }
      state.userDataReg = data;
      state.isLoading = false;
    },
    [getAccountDataByRegister.rejected]: (state, action) => {
      if (action.payload.data.email) {
        state.error = action.payload.data.email;
      } else if (action.payload.data.password) {
        state.error = action.payload.data.password;
      }
      state.isLoading = false;
    },
    [getUser.pending]: (state) => {
      state.isLoading = true;
    },
    [getUser.fulfilled]: (state, action) => {
      const data = action.payload;
      const churchId = localStorage.getItem('newChurchId');
      const church = data.church_memberships.find(
        (item) => item.church.id === +churchId
      );
      const adminChurch = data.admin_memberships.find(
        (item) => item.church.id === +churchId
      );
      state.userData = data;
      state.groupNames = church
        ? church.church.group_type_verbose_names
        : adminChurch.church.group_type_verbose_names;
      state.isLoading = false;
      state.pendingConnection = data.family_connections.filter(
        (item) =>
          item.status === familyMemberStatus.PENDING &&
          item.added_user.id === data.id
      );
      state.removedConnections = data.family_connections.filter(
        (item) =>
          item.status === familyMemberStatus.REMOVED &&
          item.removed_by !== data.id
      );

      state.userChurch = church || adminChurch;
      Sentry.setUser({ id: data.id, email: data.email });
    },
    [getUser.rejected]: (state) => {
      state.isLoading = true;
    },
    [updateProfile.pending]: (state) => {
      state.isLoading = true;
    },
    [updateProfile.fulfilled]: (state, action) => {
      state.userData = action.payload;
      state.isLoading = false;
    },
    [updateProfile.rejected]: (state) => {
      state.isLoading = true;
    },
    [getUserByGoogleToken.pending]: (state) => {
      state.isLoading = true;
    },
    [getUserByGoogleToken.fulfilled]: (state, action) => {
      const { data } = action.payload;
      localStorage.setItem('access_token', data.access_token);
      localStorage.setItem('refresh_token', data.refresh_token);

      const date = new Date();
      date.setSeconds(date.getSeconds() + +data.expires_in);
      localStorage.setItem('expires_in', date);
      state.userData = data.user;
      state.isLoading = false;
      Sentry.setUser({ id: data.user.id, email: data.user.email });
    },
    [getUserByGoogleToken.rejected]: (state, action) => {
      state.error = action.payload.data?.non_field_errors || '';
      state.isLoading = false;
    },
    [getUserByAppleToken.pending]: (state) => {
      state.isLoading = true;
    },
    [getUserByAppleToken.fulfilled]: (state, action) => {
      const data = action.payload;
      localStorage.setItem('access_token', data.access_token);
      localStorage.setItem('refresh_token', data.refresh_token);

      const date = new Date();
      date.setSeconds(date.getSeconds() + +data.expires_in);
      localStorage.setItem('expires_in', date);
      state.userData = data.user;
      state.isLoading = false;
      Sentry.setUser({ id: data.user.id, email: data.user.email });
    },
    [getUserByAppleToken.rejected]: (state, action) => {
      state.error = action.payload.data.access_token;
      state.isLoading = false;
    },
    [updateAvatar.pending]: (state) => {
      state.isLoading = true;
    },
    [updateAvatar.fulfilled]: (state, action) => {
      const data = action.payload;
      state.userData.avatar = data.data.profile_photo;
      state.isLoading = false;
    },
    [updateAvatar.rejected]: (state) => {
      state.isLoading = true;
    },
    [addRsvpDP.pending]: (state) => {
      state.isLoading = true;
    },
    [addRsvpDP.fulfilled]: (state, action) => {
      const data = action.payload;
      state.userData.rsvps.push(data.data.data), (state.isLoading = false);
    },
    [addRsvpDP.rejected]: (state) => {
      state.isLoading = true;
    },
    [updateGroupNames.pending]: (state) => {
      state.isLoading = true;
    },
    [updateGroupNames.fulfilled]: (state, action) => {
      state.groupNames = action.payload.data.data;
      state.isLoading = false;
    },
    [updateGroupNames.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export const {
  clearToken,
  signOut,
  clearRegisterData,
  clearErrors,
  setUserCredentials,
  switchTheChurch,
  setRunTourInStore,
} = AuthSlice.actions;

export default AuthSlice.reducer;
